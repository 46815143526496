//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";


interface ColorGradient {
  name: string;
  gradient: string;
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productData: { name: string; price: number; description: string; images:{url:string}[] };
  isloading: boolean;
  focusedImageIndex: number;
  product_id:number
  meters: number,
  open: boolean,
  pricePer100Meters: number,
  pricePerMeter: number,
  totalPrice: number
  tokenMain: string,
  selectedColor: string,
  colorGradients: ColorGradient[];
  isZoomed: boolean;
  isOutOfStock:boolean;
  isCottonType: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      isloading: false,
      productData: { name: "", price: 1, description: "", images: [{url:""}] },
      product_id: 8,
      focusedImageIndex:1,
      open: false,
      meters: 15,
      pricePer100Meters: 450,
      pricePerMeter: 4.5,
      totalPrice: 10 * 4.5,
      tokenMain: "",
      selectedColor: 'teal',
      colorGradients: [
        { name: 'teal', gradient: 'linear-gradient(225.01deg, #FFEED8 50.53%, #FEE1BC 50.53%, #FFECD7 101.04%)' },
        { name: 'white', gradient: 'linear-gradient(225.01deg, #FDFDFD 50.53%, #F2F2F2 50.53%, #FDFDFD 101.04%)' },
        { name: 'black', gradient: 'linear-gradient(225.01deg, #010101 50.53%, #242424 50.53%, rgba(1, 1, 1, 0.866667) 101.04%)' },
        { name: 'gray', gradient: 'linear-gradient(225.01deg, #D9D9D9 50.53%, #BEBEBE 50.53%, rgba(217, 217, 217, 0.866667) 101.04%)' },
        { name: 'golden', gradient: 'linear-gradient(225.01deg, #ECB907 50.53%, #D3A501 50.53%, rgba(236, 185, 7, 0.866667) 101.04%)' },
        { name: 'yellow', gradient: 'linear-gradient(225.01deg, #F0E68C 50.53%, #DCD172 50.53%, rgba(240, 230, 140, 0.866667) 101.04%)' },
        { name: 'floralwhite', gradient: 'linear-gradient(225.01deg, #FFFFF0 50.53%, #F2F2D7 50.53%, rgba(255, 255, 240, 0.866667) 101.04%)' },
      ],
      isZoomed: false,
      isOutOfStock: false,
      isCottonType: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    }

    // Customizable Area End
  }

  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();
    const cottonType = await getStorageData("cotton_type");
    this.setState({
      isCottonType: cottonType
    })
    const authToken = await getStorageData("accessToken");
    this.setState({ 
      tokenMain: authToken
    });

  }

  handleColorSelect = (color: string) => {
    this.setState({ selectedColor: color });
  };

  handleIncrease = () => {
    this.setState((prevState) => {
      const newMeters = prevState.meters + 1;
      const newPrice = newMeters * prevState.pricePerMeter;
      return {
        meters: newMeters,
        totalPrice: newPrice,
      };
    });
  };

  handleDecrease = () => {
    this.setState((prevState) => {
      if (prevState.meters > 10) {
        const newMeters = prevState.meters - 1;
        const newPrice = newMeters * prevState.pricePerMeter;
        return {
          meters: newMeters,
          totalPrice: newPrice, 
        };
      }
      return null;
    });
  };

 handleZoomClick = () => {
    this.setState((prevState) => ({
      isZoomed: !prevState.isZoomed,
    }));
  };
  // Customizable Area End
}
